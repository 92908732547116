<template>
  <div class="persCenter-container">
    <personal-detail :userId="userId"></personal-detail>
  </div>
</template>
<script>
import PersonalDetail from "../../components/common/personalDetail.vue"
// import { Config } from "../../utils/index.js";
export default {
  components: { PersonalDetail },
  data() {
    return {
      userId: this.$route.query.userId
      // userId: this.$sto.get(Config.constants.userInfo).user_id
    }
  },
  methods: {
    
  },
};
</script>
<style lang="less" scoped>
.persCenter-container {
  text-align: left;
}
</style>
